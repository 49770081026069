import React, { useRef, useEffect, useState, useContext, useInsertionEffect, useSyncExternalStore } from "react";

import MapView from "@arcgis/core/views/MapView";
import esriConfig from "@arcgis/core/config";
import IconWidget from "./__mocks__/@arcgis/core/widgets/IconWidget";
import IconWidgetMulti from "./__mocks__/@arcgis/core/widgets/IconWidgetMulti";
import LayerList from "@arcgis/core/widgets/LayerList";
import DateWidgetHeatMap from "./__mocks__/@arcgis/core/widgets/DateWidgetHeatMap";
import SearchToGraphic from "./__mocks__/@arcgis/core/widgets/AddSearchToGraphicBtn.js";
import Basemap from "@arcgis/core/Basemap";
import DataWidget from "./__mocks__/@arcgis/core/widgets/DataWidget";
import VectorTileLayer from "@arcgis/core/layers/VectorTileLayer";
import WebMap from "@arcgis/core/WebMap";
import Graphic from "@arcgis/core/Graphic";
import Accessor from "@arcgis/core/core/Accessor";
import GroupLayer from "@arcgis/core/layers/GroupLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Point from "@arcgis/core/geometry/Point.js";
import Polyline from "@arcgis/core/geometry/Polyline.js";
import Multipoint from "@arcgis/core/geometry/Multipoint.js";
import * as locator from "@arcgis/core/rest/locator.js";

import ButtonApp from "./ButtonApp";
import { MapContext } from "./MapContext";
import * as renderInfo from "./PopUpRenderer/renderPopUp";
import contextToLayer from "./helpers/contextToLayer";
import Sidebar from "./__mocks__/@arcgis/core/widgets/Sidebar";
import CompareSidebar from "./__mocks__/@arcgis/core/widgets/CompareSidebar";
//import Grid from "./__mocks__/@arcgis/core/widgets/Grid";
import Grid from "./__mocks__/@arcgis/core/widgets/Grid.js";

import { analyticsRenderer } from "./PopUpRenderer/renderPopUp";
import { extent, dayLayer, nightLayer, hexGrid, operator } from "./Services/serviceLayers";
import { schoolPre, schoolElementary, schoolHigher, schoolBasic, hospital, dentist, publicOffice, busStops, busRoutes, blackSpots, parking, parkingBike  } from "./Services/serviceAdditionalData";

import "./App.css";
import "./Judumas.css"
import Header from "./Header";
import { simpleRender } from "./PopUpRenderer/renderSimpleRenderer";
import ZoomAndQuery from "./__mocks__/@arcgis/core/widgets/ZoomAndQuery";
import urlsImport from "./Services/services.js"
import HideSidebar from "./__mocks__/@arcgis/core/widgets/HideSideBar";
import Legend from "./__mocks__/@arcgis/core/widgets/Legend";
import {queryFeaturesOverLimit} from "./helpers/helper";
import Lookup from "./__mocks__/@arcgis/core/widgets/Lookup.js";
import Search from "@arcgis/core/widgets/Search";
import InfoCard from "./__mocks__/@arcgis/core/widgets/InfoCard.js";
import services from "./Services/services.js";
import ContextLayers from "./__mocks__/@arcgis/core/widgets/ContextLayers.js";

const delay = ms => new Promise(res => setTimeout(res, ms));

function Appv2() {
  const mapContext = useContext(MapContext);
  //const mapFeatureLayer = mapContext.state.featureLayer;
  //const mapFeatureUrl = mapContext.state.url;

  const [MapValue, setMapValue] = useState(0);

  const [initialRender, setInitialRender] = useState(0);
  const [mapState, setMapState] = useState(new WebMap());
  const [viewState, setViewState] = useState(new MapView());
  const [fetchedLayers, setFetchedLayers] = useState([]);
  const [token, setToken] = useState(null);
  const [authorization, setAuthorization] = useState(false);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [zoomVisualFlag, setZoomVisualFlag] = useState(-1);
  const [seletedUpdateFlag, setSeletedUpdateFlag] = useState(false);
  const [search, setSearch] = useState(new Search());

  const [response, setResponse] = useState(null);
  const [searchObj , setSearchObj] = useState(null);
  const [layerData, setLayerData] = useState(["Paspauskite ant linijos", { month: "nėra duomenų", date: "nėra duomenų", sum_total: "nėra duomenų" }]);
  // SERVICE knows which service is in use, this is used to query DATA

  //Services are maps that are taken from arcgisOnline page of Vilniaus Planas
  //featureServices are Maps ready to be uploaded to UI

  //var serviceArr = []

  let mapDiv = useRef(null);

  // const widgetDiv = useRef(null);

  useEffect(() => {
    if (mapDiv.current) {
      let shouldRender = initialRender;

      let url = window.location;
      let tokenURL = url.hash
        .split("&")
        .filter(function (el) {
          if (el.match("access_token") !== null) return true;
        })[0]
        .split("=")[1];

      async function TokenGenerate() {
        let tokenLocal = token;
        esriConfig.request.interceptors.push({
          // set the `urls` property to the URL of the FeatureLayer so that this
          // interceptor only applies to requests made to the FeatureLayer URL
          //urls: [mapContext.state.url, mapContext.state.dataFeatureLayer, "https://opencity.vplanas.lt/arcgis/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer"],
          urls: ["https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer", 
          "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Strava_trips/FeatureServer", Object.values(urlsImport), 
          "https://opencity.vplanas.lt/server/rest/services/Hosted/extentas_line/FeatureServer",
          "https://opencity.vplanas.lt/server/rest/services/Hosted/VDA_2023_m_veikiantys_ukio_subjektai__Ikimokyklinis_ugdymas/FeatureServer",
          "https://opencity.vplanas.lt/server/rest/services/Hosted/VDA_2023_m_veikiantys_ukio_subjektai__pradinis_ugdymas/FeatureServer",
          "https://opencity.vplanas.lt/server/rest/services/Hosted/VDA_2023_m_veikiantys_ukio_subjektai__Vidurinis_ugdymas/FeatureServer",
          "https://opencity.vplanas.lt/server/rest/services/Hosted/VDA_2023_m_veikiantys_ukio_subjektai__Aukstasis_mokslas/FeatureServer",
          "https://opencity.vplanas.lt/server/rest/services/Hosted/VDA_2023_m_veikiantys_ukio_subjektai__Ligonines/FeatureServer",
          "https://opencity.vplanas.lt/server/rest/services/Hosted/VDA_2023_m_veikiantys_ukio_subjektai__Med_ir_odon_veikla/FeatureServer",
          "https://opencity.vplanas.lt/server/rest/services/Hosted/Administraciniai_pastatai_su_100_ir_daugiau_darbuotoj%C5%B3/FeatureServer",
          "https://opencity.vplanas.lt/server/rest/services/Hosted/NK_data/FeatureServer",
          "https://opencity.vplanas.lt/server/rest/services/Hosted/DN_data/FeatureServer",
          "https://opencity.vplanas.lt/server/rest/services/Hosted/Hexagon250/FeatureServer",
          "https://opencity.vplanas.lt/server/rest/services/Testavimai/OSM_Keliai_line_v04/GeocodeServer",
          "https://opencity.vplanas.lt/map/rest/services/Aplinkosauga/Meteo_oro_salygos/MapServer"
          ],

          error: function (error) {
            //console.log(error)
          },

          before: async function (params) {
            if (tokenLocal === null) {
              setToken(tokenURL);
              params.requestOptions.query.token = tokenURL;
            } else {
              params.requestOptions.query.token = tokenLocal;
            }
            params.requestOptions.query = params.requestOptions.query || {};
          },
        });


        let fetchedLayersLocal = fetchedLayers;

        if (!fetchedLayersLocal.includes(mapContext.state.featureLayer.id)) {
          setFetchedLayers([...fetchedLayers, mapContext.state.featureLayer.id]);
        }
        let layer = mapContext.state.featureLayer;

        
        
        
        // adding map to UI

        if (shouldRender === 1) {
          let updateMap = mapState;
          updateMap.add(layer);
          setMapState(updateMap);

          

        } else {
          const vector = new VectorTileLayer({
            portalItem: {
              id: "310451258aa04b2095c3526917cfb372",
            },
          });
          const basemap = new Basemap({
            baseLayers: [vector],
          });
          const map = new WebMap({
            basemap: basemap,
          });
          setMapState(map);

          const view = new MapView({
            container: mapDiv.current,
            map: map,
            center: [25.2797, 54.6872],
            zoom: 14,
          });

          mapContext.dispatch({
            mapView: view,
          });

          

          const searchWidget = new Search({
            view: view,
            includeDefaultSources: false,
            sources: [{

              url: "https://opencity.vplanas.lt/server/rest/services/Testavimai/OSM_Keliai_line_v04/GeocodeServer",
              singleLineFieldName: 'Name',
              outFields: ['*'],
              name: 'ID Vilnius Locator',
              maxSuggestions: 6,
              minSuggestCharacters: 0,
              suggestionsEnabled: true,
              getResults: (params) => {
                
                const customParams = { ...params };
                delete customParams.magicKey;
                const addressParam = {};
                addressParam[searchWidget.activeSource.singleLineFieldName] = params.suggestResult ? params.suggestResult.text : params.searchTerm;
                customParams.f = 'json';
                customParams.address = addressParam;

                return locator.addressToLocations(searchWidget.activeSource.url, customParams);
              },
              
            }],
            popupEnabled: false,
            resultGraphicEnabled: false
          });

          setSearch(searchWidget)
          //console.log(searchWidget.activeSource.getResults)

          //console.log(searchWidget)
            // Adds the search widget below other elements in
            // the top left corner of the view
            view.ui.add(searchWidget, {
              position: "top-right",
              index: 2
          });

          searchWidget.on("suggest-complete", function(event){
            console.log(event);
            
          });

          searchWidget.on("search-start", function(event){
            console.log(event);
            
          });
        
        
          searchWidget.on("search-complete", (result) => {
            console.log(result)
            setSearchObj(result)
            
            
          })

          view.on("click", async function (event) {
            console.log("click")
            console.log(event)
            const layer = fetchedLayers.forEach((element) => {
              if (element.visible) return element;
              else return null;
            });
            var opts = {
              include: layer,
            };
            var data = await view.hitTest(event, opts);
            console.log("hitTest")
            console.log(data)
            //console.log(data)
            data.searchFlag = false
            setResponse(data);
            //console.log(data)
            //console.log(response)
          });

          
          // double-click
          //


          const layerlist = new LayerList({
            view: view,
          });
          // comment line bellow for PROD build
          //view.ui.add(layerlist, "top-right");

          let layerGraphics = new GraphicsLayer();
          map.add(layerGraphics)
          map.add(layer);
          map.add(extent)

          // add context layers
          
          map.add(schoolPre);
          map.add(schoolBasic);
          map.add(schoolElementary);
          map.add(schoolHigher);

          map.add(hospital);
          map.add(dentist);
          map.add(publicOffice);

          

          map.add(hexGrid);
          map.add(blackSpots);
          map.add(parking);
          map.add(parkingBike)
          console.log(blackSpots)
          

          map.add(busStops);
          map.add(busRoutes);
          let dataContext = [schoolPre,schoolBasic,schoolElementary, schoolHigher, hospital, dentist, publicOffice, busStops, busRoutes, blackSpots, parking, parkingBike]
          let reachContext = [hexGrid, dayLayer, nightLayer]
          //console.log("mapContext.state.dataContextLayers")
         // console.log(dataContext)
          mapContext.dispatch({
            dataContextLayers: [...dataContext]
          });
          mapContext.dispatch({
            reachLayers: [...reachContext]
          });
          //console.log("mapContext.state.dataContextLayers")
         // console.log(mapContext.state.dataContextLayers)
          
          view.on("pointer-move", async function (event) {
            const opts = {
              include: [schoolPre, schoolBasic, schoolElementary, schoolHigher]
            }
            view.hitTest(event, opts).then((response) => {
              // check if a feature is returned from the hurricanesLayer
              if (response.results.length > 0){
                //console.log(response)
              } else {
                //console.log("Close")
              }
              
            });
          });
          

          let extentGeoQuery = extent.createQuery();
          extentGeoQuery.where = "1=1";

          extent.queryFeatures(extentGeoQuery).then((result) => {
            //console.log(result.features[0].geometry)
            mapContext.dispatch({
              extentGeometry: result.features[0].geometry,
            });
          })
          
          setZoomVisualFlag(0)
        }

        //GET max value of feature layer
        if (mapContext.state.data === "analytics" || MapValue !== 0) {
          mapContext.dispatch({
            maxValue: 0,
          });
          setZoomVisualFlag(4);
          return
        } else 
        if (mapContext.state.data.toLowerCase() === "operator") {
          
          //Add dynamic renderer once i have data in op rates hour
          var populationChangeDefinition = {
            onStatisticField: "usage", // service field for 2015 population
            outStatisticFieldName: "max_value",
            statisticType: "max",
          };
        } else {
          var populationChangeDefinition = {
            onStatisticField: "sum_total", // service field for 2015 population
            outStatisticFieldName: "max_value",
            statisticType: "max",
          };
        }
        
        //Add dynamic renderer once i have data in op rates hour
        let query = mapContext.state.featureLayer.createQuery();
        query.outStatistics = [populationChangeDefinition];
        mapContext.state.featureLayer.queryFeatures(query).then(function (response) {
          let stats = response.features[0].attributes;
          //console.log(stats);
          mapContext.dispatch({
            maxValue: stats.max_value,
          });
          setZoomVisualFlag(4);
          
        });
        
      }

      TokenGenerate();

      if (shouldRender === 1) {
        
        return;
      }
    }

    setInitialRender(1);
  }, [MapValue, mapContext.state.featureLayer, authorization]);

  useEffect(() => {
    if (initialRender === 1) {
      if (mapContext.state.transportation !== "Car") {
        mapContext.state.featureLayer.visible = false;
      }
      console.log("REELOAD")
      if (MapValue === 3) {
        mapContext.state.dataContextLayers.forEach((layer, index) => {
          if (index != mapContext.state.dataContextLayers.length)
          layer.visible = true;
          console.log(mapContext.state.dataContextLayers[index].visible)
        });
        console.log(mapContext.state.dataContextLayers)
        console.log("mapContext.state.dataContextLayers")
        mapContext.state.reachLayers[0].visible = true
      } else {
        mapContext.state.dataContextLayers.forEach(layer => {
          layer.visible = false;
        });
        mapContext.state.reachLayers[0].visible = false
      }
    }
  }, [MapValue]);

  useEffect(() => {
    if (initialRender === 0) {
      return
    }
    search.clear()
    if (MapValue !== 1) {
      mapContext.dispatch({
        searchToGraphic: false,
    });  
    }
    if (MapValue === 0 ){
      document.getElementsByClassName("esri-search")[0].style.width = "240px"
      document.getElementsByClassName("esri-search__input-container")[0].style.display = "block"
      document.getElementsByClassName("esri-search")[0].style.display = "block"
      return
    } if (MapValue === 3) {
      document.getElementsByClassName("esri-search")[0].style.display = "none"
      document.getElementsByClassName("esri-search__input-container")[0].style.display = "none"
      return
    } else {
      document.getElementsByClassName("esri-search")[0].style.width = "40px"
      document.getElementsByClassName("esri-search__input-container")[0].style.display = "none"
      document.getElementsByClassName("esri-search")[0].style.display = "block"
      document.getElementsByClassName("esri-search")[0].onclick = () => {
        mapContext.dispatch({
          searchFlag: 1,
        });        
      }   
      return
    }
  },[MapValue])

  useEffect(() => {
    console.log("A")
    if (mapContext.state.data === "analytics" && MapValue !== 0) {
      console.log("B")
      return

    }
    if (mapContext.state.data.toLowerCase() === "operator") {
      mapContext.state.featureLayer.renderer = renderInfo.trailsRendererOperatorDynamic(mapContext.state.maxValue);
    } else if (mapContext.state.data.toLowerCase() === "strava") {
      mapContext.state.featureLayer.renderer = renderInfo.trailsRendererStravaPedestrians(mapContext.state.maxValue);
    }/*
    if (mapContext.state.transportation == "Pedestrian") {
      //console.log("human");
      
    } else if (mapContext.state.transportation == "Car") {
      //console.log("1",mapContext.state.featureLayer.renderer);
      
      //console.log("2",mapContext.state.featureLayer.renderer);
    } else if (mapContext.state.transportation == "Bike") {
      //console.log("bike");
      if (mapContext.state.data.toLowerCase() !== "operator"){
        mapContext.state.featureLayer.renderer = renderInfo.trailsRendererStravaPedestrians(mapContext.state.maxValue);
      } else {
        mapContext.state.featureLayer.renderer = renderInfo.trailsRendererOperatorDynamic(mapContext.state.maxValue);
      }
      
    }*/
    if (MapValue === 0 && mapContext.state.maxValue !== null && mapContext.state.maxValue !== undefined){
      console.log(mapContext.state.featureLayer.renderer)
      mapContext.state.featureLayer.visible = true;
    }
  }, [mapContext.state.maxValue]);

  useEffect(() => {
    //console.log("mapContext",mapContext)
    if (mapContext.state.time !== null && mapContext.state.transportation !== null && mapContext.state.data !== null) {
    
      let contextToLayer_param = { data: null, time: null, transportation: null}
      if (mapContext.state.data.toLowerCase() === "analytics") 
      { contextToLayer_param.data = 2; contextToLayer_param.time = 0; contextToLayer_param.transportation = 0} else 
      {
        if (mapContext.state.data.toLowerCase() === "strava") { contextToLayer_param.data = 0}
        if (mapContext.state.data.toLowerCase() === "operator") { contextToLayer_param.data = 1}
      
        if (mapContext.state.transportation.toLowerCase() === "bike") { contextToLayer_param.transportation = 0}
        if (mapContext.state.transportation.toLowerCase() === "pedestrian") { contextToLayer_param.transportation = 1}
        if (mapContext.state.transportation.toLowerCase() ==="car") { contextToLayer_param.transportation = 2}
        if (mapContext.state.transportation.toLowerCase() ==="bus") { contextToLayer_param.transportation = 3}

        if (mapContext.state.time.toLowerCase() === "day") { contextToLayer_param.time = 0}
        if (mapContext.state.time.toLowerCase() === "week") { contextToLayer_param.time = 1}
        if (mapContext.state.time.toLowerCase() === "month") { contextToLayer_param.time = 2}
        if (mapContext.state.time.toLowerCase() === "year") { contextToLayer_param.time = 3}
      }

      if (MapValue === 0) {
        setZoomVisualFlag(0);
      }
      if (MapValue !== 0) {
        contextToLayer_param.data = 2;
      }

      console.log("contextToLayer_param",contextToLayer_param)
      
      let obj_2 = contextToLayer.contextToLayer_02(contextToLayer_param.data, contextToLayer_param.transportation, contextToLayer_param.time)

      //console.log("obj_2", obj_2)
      if (obj_2.featureLayer === undefined || obj_2.url === undefined) {
        return
      }

      if (contextToLayer_param.data == 2) {
        //let dataLayer = contextToLayer.contextToLayer_02(2, 0, 1)
        if (mapContext.state.transportation.toLowerCase() ==="car"){
          mapContext.dispatch({
            dataFeatureLayer: contextToLayer.contextToLayer_02(2, 2, 1).featureLayer,
          });
          mapContext.dispatch({
            dataurl: contextToLayer.contextToLayer_02(2, 2, 1).url,
          });
        } else if (mapContext.state.transportation.toLowerCase() ==="bike") {
          mapContext.dispatch({
            dataFeatureLayer: contextToLayer.contextToLayer_02(2, 0, 1).featureLayer,
          });
          mapContext.dispatch({
            dataurl: contextToLayer.contextToLayer_02(2, 0, 1).url,
          });
        } else if (mapContext.state.transportation.toLowerCase() ==="pedestrian") {
          mapContext.dispatch({
            dataFeatureLayer: contextToLayer.contextToLayer_02(2, 1, 1).featureLayer,
          });
          mapContext.dispatch({
            dataurl: contextToLayer.contextToLayer_02(2, 1, 1).url,
          });
        } else if (mapContext.state.transportation.toLowerCase() ==="bus") {
          mapContext.dispatch({
            dataFeatureLayer: contextToLayer.contextToLayer_02(2, 3, 1).featureLayer,
          });
          mapContext.dispatch({
            dataurl: contextToLayer.contextToLayer_02(2, 3, 1).url,
          });
        }
      }

      
      mapContext.dispatch({
        featureLayer: obj_2.featureLayer,
      });
      mapContext.dispatch({
        url: obj_2.url,
      });
      mapContext.state.featureLayer.clone()
      
    }
  }, [mapContext.state.time, mapContext.state.transportation, mapContext.state.data]);


  useEffect(() => {
    console.log("Graphic")
    console.log(mapContext.state.mapView.graphics)
    if (mapContext.state.mapView.graphics === null && mapContext.state.extentGeometry !==null){
      let symbol = {
        type: "simple-line",  // autocasts as new SimpleLineSymbol()
        color: [232,76,49, 1],
        width: "4px",
        style: "solid"
      };
      var graphic = new Graphic(mapContext.state.extentGeometry, symbol);
      mapContext.state.mapView.graphics.add(graphic);
      return
    }
    if (mapContext.state.extentGeometry !==null ) {
      let symbol = {
        type: "simple-line",  // autocasts as new SimpleLineSymbol()
        color: [232,76,49, 1],
        width: "4px",
        style: "solid"
      };
      console.log("mapContext.state.extentGeometry")
      console.log(mapContext.state.extentGeometry)
      var graphic = new Graphic(mapContext.state.extentGeometry, symbol);
      mapContext.state.mapView.graphics.add(graphic);
    }
  }, [mapContext.state.extentGeometry, mapContext.state.clearFlag, MapValue]);

  useEffect(() => {
    console.log("CLEAR ALL1")
    
    
    if (initialRender === 0) {
      return;
    }

    mapContext.dispatch({
      contextDataVisible: false,
    });
    mapContext.dispatch({
      layerLoading: 0,
    });
    mapContext.dispatch({
      calendarResetDates: 0,
    });
    if(MapValue !== 2){
      mapContext.dispatch({
        compareTask: 0,
      });
    }
    
    if (MapValue === 1 || MapValue === 2) {
      console.log("CLEAR LALL")
      mapContext.state.featureLayer.visible = false;
      mapContext.state.featureLayer.popupTemplate = null;

      mapContext.dispatch({
        data: "analytics",
      });
      mapContext.dispatch({
        time: "Day",
      });
      mapContext.dispatch({
        transportation: "Car",
      });
      
      //queryUnique()
    } else {
      
      mapContext.state.featureLayer.visible = false;
      mapContext.state.featureLayer.popupTemplate = null;
      //mapContext.state.mapView.removeEventListener("click", higlight(), false);
      mapContext.dispatch({
        time: "Day",
      });
      mapContext.dispatch({
        transportation: "Car",
      });
      mapContext.dispatch({
        data: "operator",
      });
      mapContext.dispatch({
        selectedStreetArray: [],
      });
      mapContext.dispatch({
        dateRange: 0,
      });
      
      mapContext.state.mapView.graphics.removeAll();
      mapContext.dispatch({
        clearFlag: 1,
      });
      //mapContext.state.mapView.layerViews.items[0].filter = null;
      setLayerData(["Paspauskite ant linijos", { month: "nėra duomenų", date: "nėra duomenų", sum_total: "nėra duomenų" }]);
    }
  }, [MapValue]);

  useEffect(() => {
    if (mapContext.state.clearFlag === 1){
      mapContext.state.mapView.graphics.removeAll();
      setLayerData(["Paspauskite ant linijos", { month: "nėra duomenų", date: "nėra duomenų", sum_total: "nėra duomenų" }]);
      mapContext.dispatch({
        clearFlag: 0,
      });
      mapContext.dispatch({
        selectedStreetArray: [],
      });
    }
  }, [mapContext.state.clearFlag]);

  useEffect(() => {
    console.log(response)
    if (MapValue === 3) {

      let i
      for (i = 0;i<response.results.length; i++  ){
        
        if (response.results[i].layer !== null) {
          if (response.results[i].layer.url === hexGrid.url){
            
            mapContext.dispatch({
              gridClickData: response.results[i]
            });
          }
        }
      }
      
    }
    if (MapValue !== 1 || mapContext.state.sideBarRequestPending === 1 || mapContext.state.dateRange === null || response.results.length === 1) {
      return;
    }
    console.log(response)
    /*

    Add support once Data is added to 
    op_rates_hour
    */
    var concPath = []
    async function getData(searchFlag) {

      mapContext.dispatch({
        sideBarRequestPending: 1,
      });
      //console.log("Selected streets", mapContext.state.selectedStreetArray)
      if (response !== null && response.results.length !== 0) {
        var data;

        var diagramData = {};
        var changeFlag = false;
        if (layerData[2] !== null && layerData[2] !== undefined) {
          diagramData = layerData[2];
        }
        if (layerData[3] !== null && layerData[3] !== undefined) {
          changeFlag = !layerData[3];
          console.log(changeFlag)
        }
        //console.log(diagramData)
        // New unquery graphic
        if (response.results[0].layer === null) { 
          let queryWhere = "parent_global_id IN ('"
          response.results[0].graphic.attributes.selectedStreetIds.forEach(element => {
            queryWhere += element + "','"
          });
          queryWhere = queryWhere.slice(0,-2)
          queryWhere += ") and " + mapContext.state.dateRange;

          var query = mapContext.state.dataFeatureLayer.createQuery();
          query.where = queryWhere;
          const features = await queryFeaturesOverLimit(mapContext.state.dataFeatureLayer, query)
          if (features.length === 0) {
            mapContext.dispatch({
              clearFlag: 1,
            });
            mapContext.dispatch({
              sideBarRequestPending: 0,
            });
            return
          }

          let proccesedCount = 0;
          features.forEach((element) => {
            proccesedCount++
            // year: 'numeric', month: 'numeric', day: 'numeric',
            const options = { hour: "numeric" };
            // Atimti kelias atkarpas
            console.log("Remove")
            diagramData[new Date(element.attributes.datetime).toLocaleDateString("en-US", options)] -= element.attributes.usage;
            if (proccesedCount == features.length) {
              mapContext.dispatch({
                sideBarRequestPending: 0,
              });
            }
          });

          data = ["Operatorių duomenys", { date: null, sum_total: null }, diagramData, changeFlag];
          setLayerData(data);
          console.log("Removed data " + data)
          return
        }


        //
        if (response.results.length >= 2) {
          var id;
          let layerflag = true;
          let layerindex = 0;
          while (layerflag === true) {
            if (response.results[layerindex].layer !== null){
              if (response.results[layerindex].layer.url === "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer") {
                id = response.results[layerindex].graphic.attributes.GlobalID;
                layerflag = false;
              }
            }
            
            layerindex++;
            if (layerindex === response.results.length) {
              layerflag = false;
              data = ["Operatorių duomenys", { date: null, sum_total: null }, diagramData];
              setLayerData(data);
              return;
            }
          }

          let filteredResponse = [...response.results]
          //console.log("filteredResponse BEFORE", filteredResponse)
          filteredResponse = filteredResponse.filter( feature => feature.layer !== null)
          
         /*if (response.results[0].layer === null) {
            filteredResponse.shift()
          }*/
          //console.log("filteredResponse AFTER", filteredResponse)
          if (searchFlag === false){
            filteredResponse.pop()
          }      
          console.log("filteredResponse")
          console.log(filteredResponse)
          let selectedIds = []
          let str = "parent_global_id IN ('"
          for (const singleId of filteredResponse) {
            selectedIds.push(singleId.graphic.attributes.GlobalID)
            if (singleId.graphic.attributes.GlobalID !== undefined && singleId.graphic.attributes.GlobalID !== null){
              str += singleId.graphic.attributes.GlobalID + "','"
            }
          }
          //console.log(response.results)
          let i = 0
          if (selectedIds.includes(response.results[0].graphic.attributes.id)) {
            i = 1
          }

          str = str.slice(0,-2)
          str += ") and " + mapContext.state.dateRange;
          //console.log(str)
          var query = mapContext.state.dataFeatureLayer.createQuery();
          query.where = str;
          // Sita perdaryt i metoda kuris tikrina kiek irasu ir querina irasus
          //const results = await mapContext.state.dataFeatureLayer.queryFeatures(query);
          console.log("Get data query + features")
          console.log(query)
          const features = await queryFeaturesOverLimit(mapContext.state.dataFeatureLayer, query)
          console.log(features)
          if (features.length === 0) {
            mapContext.dispatch({
              clearFlag: 1,
            });
            mapContext.dispatch({
              sideBarRequestPending: 0,
            });
            return
          }
         // console.log("all osm_id:", features);
          //const features = results.features;
          query.where = "GlobalID = '" + id + "'";
          //const stName = await mapContext.state.featureLayer.queryFeatures(query);
          //console.log(stName.features[0].attributes.highway)
          //console.log(results)
          layerflag = true;
          layerindex = 0;
          while (layerflag === true) {
            if (response.results[layerindex].layer !== null) {
              // response.results[0].graphic.attributes.id == response.results[layerindex].graphic.attributes.GlobalID
              let proccesedCount = 0
              if (selectedIds.includes(response.results[0].graphic.attributes.id)) {
                features.forEach((element) => {
                  proccesedCount++
                  // year: 'numeric', month: 'numeric', day: 'numeric',
                  const options = { hour: "numeric" };
                  // Atimti kelias atkarpas
                  console.log("Remove")
                  diagramData[new Date(element.attributes.datetime).toLocaleDateString("en-US", options)] -= element.attributes.usage;
                  if (proccesedCount == features.length) {
                    mapContext.dispatch({
                      sideBarRequestPending: 0,
                    });
                  }
                });
              } else {
                features.forEach((element) => {
                  proccesedCount++
                  // year: 'numeric', month: 'numeric', day: 'numeric',
                  const options = { hour: "numeric" };
                  console.log("ADD")
                  if (!diagramData.hasOwnProperty(new Date(element.attributes.datetime).toLocaleDateString("en-US", options))) {
                    diagramData[new Date(element.attributes.datetime).toLocaleDateString("en-US", options)] = 0;
                  }
                  // Prideti kelias atkarpas
                  diagramData[new Date(element.attributes.datetime).toLocaleDateString("en-US", options)] += element.attributes.usage;
                  if (proccesedCount == features.length) {
                    mapContext.dispatch({
                      sideBarRequestPending: 0,
                    });
                  }
                });
              }
              layerflag = false;
            }
            layerindex++;
            if (layerindex === response.results.length) {
              layerflag = false;
            }
          }
          data = ["Operatorių duomenys", { date: null, sum_total: null }, diagramData, changeFlag];
        } else {
          data = ["Operatorių duomenys", { date: null, sum_total: null }, diagramData];
        }

        setLayerData(data);
        
      }
    }
    getData(response.searchFlag);
    if (response === null) {
      return;
    }
    //var firstLayer = response.results[0];
    //var geometry = firstLayer.graphic.geometry;
    let symbol = {
      type: "simple-fill", // autocasts as new SimpleFillSymbol()
      color: [85, 255, 255, 6],
      style: "solid",
      outline: {
        // autocasts as new SimpleLineSymbol()
        color: [85, 255, 255, 6],
        width: 6,
      },
    };
    /*let attributes = {
      id: response.results[0].graphic.attributes.GlobalID
    }
    var graphic = new Graphic(geometry, symbol, attributes);*/
    //mapContext.state.mapView.graphics.removeAll();
    // make sure to remmove previous highlighted feature
    //console.log("layer relationships", mapContext.state.featureLayer.relationships, mapContext.state.dataFeatureLayer.relationships);
    console.log(" GRAPHIC ")
    console.log(MapValue)
    console.log(mapContext.state.searchToGraphic)
    console.log(searchObj)
    if (MapValue === 0) {
      return;
    }
    /* Klausimas kam sito reikia, jis returnina , nors 1 atvejis yra padaryti grafika pagal searcha kitas pagal clicka
    if (mapContext.state.searchToGraphic === false){
      return;
    }
    */
    let layerflag = true;
    let layerindex = 0;
    while (layerflag === true) {
      if (response.results[layerindex].layer !== null) {
        let osmDuplicates = [];
        let removeDuplicates = [];
          for (let i = 0; (response.results.length-1) > i ; i ++){
            if (response.results[i].graphic.attributes.GlobalID !== undefined && response.results[i].graphic.attributes.GlobalID !== null && !(mapContext.state.selectedStreetArray.includes(response.results[i].graphic.attributes.GlobalID))){

              osmDuplicates.push(response.results[i].graphic.attributes.GlobalID)
            } else if (response.results[i].graphic.attributes.GlobalID !== undefined && response.results[i].graphic.attributes.GlobalID !== null) {
              removeDuplicates.push(response.results[i].graphic.attributes.GlobalID)
            }
            
        }
        //if (response.results[0].graphic.attributes.id == response.results[layerindex].graphic.attributes.GlobalID)
        if (response.results[0].layer === null) {
          mapContext.state.mapView.graphics.remove(response.results[0].graphic);
          var arrayTemp = [...mapContext.state.selectedStreetArray]
          //let filteredArray = arrayTemp.filter(item => !removeDuplicates.includes(item))
          let filteredArray = arrayTemp.filter(item => !response.results[0].graphic.attributes.selectedStreetIds.includes(item))
          // Salint lauk visas gatves kurios vienoje atkarpoje
          mapContext.dispatch({
            selectedStreetArray: [...filteredArray],
          });
        } else {
          let filteredResponse = [...response.results]
          filteredResponse = filteredResponse.filter( feature => feature.layer !== null)
          filteredResponse.pop()
          let selectedIds = []
          for (const singleId of filteredResponse) {
            selectedIds.push(singleId.graphic.attributes.GlobalID)
          }

          var firstLayer = response.results[layerindex];
          var geometry = firstLayer.graphic.geometry;
          let attributes = {
            id: response.results[layerindex].graphic.attributes.GlobalID,
            selectedStreetIds: [...selectedIds],
          };
          
          if (response.results[layerindex].graphic.attributes.GlobalID !== undefined){
            // Pridet visas gatves kurios yra 
            mapContext.dispatch({
              //selectedStreetArray: [...mapContext.state.selectedStreetArray, response.results[layerindex].graphic.attributes.GlobalID],
              selectedStreetArray: [...mapContext.state.selectedStreetArray, ...osmDuplicates],
            });
          } 

          if (searchObj !== null) {
            console.log("NOT GRAPHIC CLICK")
            const polylinePoints = searchObj.results[0].results.map(point => [point.location.x, point.location.y]);
            console.log(polylinePoints)
            
            polylinePoints.sort((a, b) => {
                if (a[0] === b[0]) {
                    return a[1] - b[1];
                }
                return a[0] - b[0];
            });

            const polyline = new Polyline({
              paths: [polylinePoints],
              spatialReference: { wkid: 2600 } // Assuming WGS84 coordinate system, adjust as needed
            });
            
            symbol.type = "simple-line"
            symbol.width = 6
            var graphic = new Graphic(polyline, symbol, attributes);
            setSearchObj(null)
            
          }
          else {
            console.log("ADDD GRAPHIC CLICK")
            symbol.type = "simple-line"
            symbol.width = 6
            var graphic = new Graphic(geometry, symbol, attributes);
            mapContext.state.mapView.graphics.add(graphic);
          }
          
          console.log("graphic")
          console.log(graphic)
          
        }
        layerflag = false;
      }
      layerindex++;
      if (layerindex === response.results.length) {
        layerflag = false;
      }
    }


  }, [response]);

  useEffect(() => {
    //console.log(hexGrid.renderer)
    //console.log(mapContext.state.mapView.zoom)
    if (zoomVisualFlag === -1){
      return;
    }
    if(MapValue === 3) {
      for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
        if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
          if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
            mapContext.state.mapView.layerViews.items[i].filter = null;
          }
        }
      }
      setZoomVisualFlag(-1)
      return
    }
    console.log(mapContext.state.mapView.zoom)
    if (mapContext.state.mapView.zoom < 12 && (MapValue === 0 || MapValue === 1 || MapValue === 2) &&  zoomVisualFlag !== 3) {
        setZoomVisualFlag(3);
        if(MapValue === 0 && mapContext.state.data.toLowerCase() === 'strava')
        {
          for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
              if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
                let whereClause
              if (mapContext.state.data.toLowerCase() === 'strava') {
                whereClause = 'sum_total'
              }
              let str =  whereClause + ' >= ' + Math.floor((mapContext.state.maxValue * 0.3) + 1)
              console.log(mapContext.state.mapView.layerViews.items[i])
              console.log( str)
              mapContext.state.mapView.layerViews.items[i].filter = {
                where: str
              };
              mapContext.state.featureLayer.renderer.visualVariables[0].maxSize = "5px";
              console.log("zoom 1")
            }
          }
        } else {
          for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
            if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
              mapContext.state.mapView.layerViews.items[i].filter = {
                where: "highway IN ('motorway', 'primary', 'trunk')"
              };
              console.log("zoom 1")
            }
          }
        }
    }

    if (mapContext.state.mapView.zoom >= 12 && mapContext.state.mapView.zoom < 13 && (MapValue === 0 || MapValue === 1 || MapValue === 2) &&  zoomVisualFlag !== 2) {
      setZoomVisualFlag(2);
      if(MapValue === 0 && mapContext.state.data.toLowerCase() === 'strava')
      {
        //sum_total - strava
        //usage - op
        for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
          if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
            let whereClause
            if (mapContext.state.data.toLowerCase() === 'strava') {
              whereClause = 'sum_total'
            }
            let str =  whereClause + ' >= ' + Math.floor((mapContext.state.maxValue * 0.3) + 1)
            console.log(mapContext.state.mapView.layerViews.items[i])
            console.log( str)
            mapContext.state.mapView.layerViews.items[i].filter = {
              where: str
            };
            //mapContext.state.featureLayer.definitionExpression = str
            mapContext.state.featureLayer.renderer.visualVariables[0].maxSize = "5px";
            console.log("zoom 2")
          }
        }
      } else {
        for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
          if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
            mapContext.state.mapView.layerViews.items[i].filter = {
              where: "highway IN ('motorway', 'motorway_link', 'primary', 'primary_link', 'trunk', 'trunk_link', 'secondary', 'secondary_link', 'tertiary', 'tertiary_link', 'unclassified')"
            };
            console.log("zoom 2")
          }
        }
      }
    }

    if (mapContext.state.mapView.zoom >= 13 && mapContext.state.mapView.zoom < 14 && (MapValue === 0 || MapValue === 1 || MapValue === 2) && zoomVisualFlag !== 1) {
      setZoomVisualFlag(1);
      
      if(MapValue === 0 && mapContext.state.data.toLowerCase() === 'strava')
      {
        for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
          if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
            if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
              mapContext.state.mapView.layerViews.items[i].filter = null;
              mapContext.state.featureLayer.renderer.visualVariables[0].maxSize = "5px";
              console.log("zoom 3")
            }
          }
        }

      } else {
        for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
          if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
            mapContext.state.mapView.layerViews.items[i].filter = {
              where: "highway IN ('motorway', 'motorway_link', 'primary', 'primary_link', 'trunk', 'trunk_link', 'secondary', 'secondary_link', 'tertiary', 'tertiary_link', 'unclassified', 'living_street', 'motorway_junction', 'residential')"
              
            };
            console.log("zoom 3")
          }
        }
      }    
    }

    if ((mapContext.state.mapView.zoom >= 14 || MapValue === 3 )&& zoomVisualFlag !== 0) {
      
        setZoomVisualFlag(0);
        
        
        if(MapValue === 0 && mapContext.state.data.toLowerCase() === 'strava')
        {
          for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
            if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
              if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
                mapContext.state.mapView.layerViews.items[i].filter = null;
                mapContext.state.featureLayer.renderer.visualVariables[0].maxSize = "5px";
                console.log("zoom 4")
              }
            }
          }
        }
        for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
          if (mapContext.state.mapView.layerViews.items[i].layer.visible === true) {
            //mapContext.state.mapView.layerViews.items[i].filter = null
            mapContext.state.mapView.layerViews.items[i].filter = {
              where: "highway IN ('motorway', 'motorway_link', 'primary', 'primary_link', 'trunk', 'trunk_link', 'secondary', 'secondary_link', 'tertiary', 'tertiary_link', 'unclassified', 'living_street', 'residential', 'motorway_junction', 'service')"
              
            };
            console.log("zoom 4")
            /*console.log( "mapContext.state.mapView.layerViews.items[i].filter")*/
            console.log( mapContext.state.mapView.layerViews.items[i])
          }
        }
      }

      
  },[mapContext.state.mapView.zoom, refreshFlag])

  async function removeHighlight(features) {

  }

  async function addHighlight(features) {
    
  }

  useEffect(() => {
    if (MapValue !== 2 ) {
      return;
    }
    //console.log(response, mapContext.state.compareTask)
    if (mapContext.state.compareTask % 10 === 1 && mapContext.state.compareTask > 1){
      for (let i = 0 ; i < response.length; i ++){
        if (response.results[i].graphic.attributes.hasOwnProperty('selected')) {
          removeHighlight(response)
          return;
        }
      }
      addHighlight(response)
    }
  }, [response])

  useEffect(() => {

    
    if (mapContext.state.selectedStreetArray.length == 0) {
      setLayerData(["Paspauskite ant linijos", { month: "nėra duomenų", date: "nėra duomenų", sum_total: "nėra duomenų" }])
      return;
    }
    
    
    if (MapValue === 0 || MapValue === 3) {
      return;
    }
    getData();


    async function getData() {

      mapContext.dispatch({
        sideBarRequestPending: 1,
      });
      var data;
      var diagramData = {};
      for (const street of mapContext.state.selectedStreetArray) {
        var query = mapContext.state.dataFeatureLayer.createQuery();
        query.where = "parent_global_id ='" + street + "' and " + mapContext.state.dateRange;
        //const results = await mapContext.state.dataFeatureLayer.queryFeatures(query);
        console.log("query")
        console.log(query)
        const queriedFeatures = await queryFeaturesOverLimit(mapContext.state.dataFeatureLayer, query)
        if (queriedFeatures.length === 0) {
          mapContext.dispatch({
            clearFlag: 1,
          });
          mapContext.dispatch({
            sideBarRequestPending: 0,
          });
          return
        }
        console.log("queriedFeatures")
        console.log(queriedFeatures)
        queriedFeatures.forEach((element) => {
          const options = { hour: "numeric" };
          if (!diagramData.hasOwnProperty(new Date(element.attributes.datetime).toLocaleDateString("en-US", options))) {
            diagramData[new Date(element.attributes.datetime).toLocaleDateString("en-US", options)] = 0;
          }
          diagramData[new Date(element.attributes.datetime).toLocaleDateString("en-US", options)] += element.attributes.usage;
        });
      }
      data = ["Operatorių duomenys", { date: response.results[0].graphic.attributes.datetime, sum_total: response.results[0].graphic.attributes.usage }, diagramData];
      setLayerData(data);
      mapContext.dispatch({
        sideBarRequestPending: 0,
      });
    }
    
  }, [mapContext.state.dateRange]);

  useEffect(() => {
    setTimeout(() => {
      setRefreshFlag(!refreshFlag)
    }, 1000);
    //console.log(mapContext.state.mapView.zoom)
    if (initialRender === 0) {
      return
    }
    mapContext.state.mapView.layerViews.items.forEach(layer => {
      if (layer.visible === true && layer.layer.url !== null)  {
        //console.log(layer.updating)
        if (layer.updating === true && mapContext.state.layerLoading === 0) {
          mapContext.dispatch({
            layerLoading: 1,
          });
        } 
        if (layer.updating === false && mapContext.state.layerLoading === 1) {
          mapContext.dispatch({
            layerLoading: 0,
          });
        }
      }
    });
  },[refreshFlag])

  useEffect(() => {
    console.log("searchObj")
    console.log(searchObj)
    if (initialRender === 0 || searchObj === null || searchObj.results[0].results.length === 0) {
      return
    }
    const polylinePoints = searchObj.results[0].results.map(point => [point.location.x, point.location.y]);
    console.log(polylinePoints)

    const streetPart = searchObj.searchTerm.split(',')[0].trim();
    
    polylinePoints.sort((a, b) => {
        if (a[0] === b[0]) {
            return a[1] - b[1];
        }
        return a[0] - b[0];
    });

    const polyline = new Polyline({
      paths: [polylinePoints],
      spatialReference: { wkid: 2600 } // Assuming WGS84 coordinate system, adjust as needed
    });

    const multipointGeometry = new Multipoint({
      points: polylinePoints,
      spatialReference: { wkid: 2600 } // Assuming WGS84 coordinate system, adjust as needed
  });

  console.log(multipointGeometry)

    console.log("search-complete result.results[0].results[0].feature")
            console.log(searchObj.results[0].results[0].extent.center.longitude, searchObj.results[0].results[0].extent.center.latitude)
            //console.log(mapContext.state.featureLayer)
            if (MapValue === 1  && mapContext.state.searchToGraphic === true){
              mapContext.dispatch({
                searchFlag: 1,
            });  
            }
            

            let query = mapContext.state.featureLayer.createQuery();
            query.geometry = multipointGeometry  // the point location of the pointer
            query.distance = 2;
            query.units = "meters";
            query.spatialRelationship = "intersects";  // this is the default
            query.returnGeometry = true;
            query.outFields = ["*"]

            //console.log(view)
            let geo = searchObj.results[0].results[0].location
            



            delayWrapper()

            async function delayWrapper() {
              await delay(5000);
              mapContext.state.mapView.goTo({
                target: polyline,
              });
            }
            //queryAll()
            let queryLayer = operator
            
            
            queryLayer.queryFeatures(query).then(async function (result) {
              console.log("nearest Street")
              console.log(result)

              //console.log(searchObj.features[0])
              let objCollection = {results : []}
              result.features.forEach(feature => {
                let singleObj = {
                  graphic: feature,
                  layer: feature.layer
                }
                objCollection.results.push(singleObj)
              });
              console.log(objCollection)

              objCollection.results = objCollection.results.filter(obj => {
                const name = obj.graphic.attributes.name;
                return name === streetPart || name === null;
              });

              console.log("response")
              console.log(objCollection)
              //console.log(filteredObjects)
              objCollection.searchFlag = true
              setResponse(objCollection);
              console.log("response")
              console.log(response)

              
              
              

              let symbolConcPoly = {
                type: "simple-line", // autocasts as new SimpleFillSymbol()
                color: [85, 255, 255, 6],
                style: "solid",
                width: 6,
                outline: {
                  // autocasts as new SimpleLineSymbol()
                  color: [85, 255, 255, 6],
                  width: 6,
                },
              };
        
        
              objCollection.results.forEach( (result) => {
                console.log(result.graphic.attributes.name)
                var graphic = new Graphic(result.graphic.geometry, symbolConcPoly);
                mapContext.state.mapView.graphics.add(graphic);
                
              });

              //MULTIPOINT FOR DEBBUGING
              var graphic = new Graphic(multipointGeometry, symbolConcPoly, {});
              
              
              mapContext.state.mapView.graphics.add(graphic);

              await delay(5000);
              console.log("DELET GRAPHIC")
              if (mapContext.state.searchToGraphic === false) {
                mapContext.state.mapView.graphics.removeAll();
              }
              
              //console.log(graphic)
              
              //console.log("DELET GRAPHIC")
              //
              
            });
            
            
    
  }, [searchObj])

  useEffect(() => {
    if (initialRender === 0) {
      return
    }
    //console.log(mapContext.state.mapView.layerViews.items[0].updating)
  }, [mapContext.state.mapView.layerViews])

  async function savaiteClick() {
    if (mapContext.state.transportation === "Car") {
      return;
    }
    if (mapContext.state.time != "Week") {
      mapContext.state.featureLayer.visible = false;
      mapContext.dispatch({
        time: "Week",
      });
    }
    return;
    
  }

  async function metaiClick() {
    if (mapContext.state.transportation === "Car") {
      return;
    }
    if (mapContext.state.time != "Year") {
      mapContext.state.featureLayer.visible = false;
      mapContext.dispatch({
        time: "Year",
      });
    }
    return;
    
  }

  /*
   */
  async function menClick() {
    if (mapContext.state.transportation === "Car") {
      return;
    }
    if (mapContext.state.time != "Month") {
      mapContext.state.featureLayer.visible = false;
      mapContext.dispatch({
        time: "Month",
      });
    }
    return;
  }

  async function dayClick() {
    if (mapContext.state.transportation === "Car") {
      return;
    }
    if (mapContext.state.time != "Day") {
      mapContext.state.featureLayer.visible = false;
      mapContext.dispatch({
        time: "Day",
      });
    }
    return;
  }

  function clearAllData() {
    mapContext.dispatch({
      selectedStreetArray: [],
    });
    mapContext.dispatch({
      dateRange: null,
    });
    //mapContext.state.mapView.graphics.removeAll();
  }
  

  return (
    <>
      <Header />
      <ButtonApp
        HeatMap={() => {
          setMapValue(0);
        }}
        DetailedMap={() => {
          // POP-up template turi rodyti antrame uzdavinyje
          // Kalendorius selectavimo galmybe datoms kurioms turim duomenu
          // Ar nereikia ismti komponentu is analitikos sidebar
          //clearAllData()
          setMapValue(1);
        }}
        CompareMap={() => {
          //clearAllData()

          setMapValue(2);
        }}
        GridMap={() => {
          setMapValue(3);
        }}
      />
      {
        (mapContext.state.layerLoading === 1 || mapContext.state.layerLoading === 2) ? 
        <div id="layerLoaderWrapper"><br/>{MapValue === 3 ?'Kraunamas Pasiekiamumas' : 'Kraunamas Sluoksnis'} <br/>
        <div class="loader loaderCentered" id="layerLoader"></div>
        </div>
        
        :
        <></>
      }
      <InfoCard></InfoCard>
      <div className="mapDiv" ref={mapDiv}>
        
        
      </div>{" "}
      {
        /*initialRender === 1 ? 
        <Lookup/> :
        ""*/
      }
      {
        // Kol nera operatoriu duomenu rodom per abu uzdavinius, 2 uzdaviny palieku tik car
        //<IconWidget handleBike = {"Bike"} handleCar= {"Car"} handleBus = {"Bus"} handleHuman = {"Pedestrian"}/>
      }
      {MapValue === 0 ? (
        <>
          <DateWidgetHeatMap handleMonth={menClick} handleDay={dayClick} handleYear={metaiClick} handleWeek={savaiteClick} />
          <DataWidget handleStrava={"Strava"} handleSISP={"SISP"} handleOperator={"Operator"} />
          
          {
            //<button style = {{position: 'fixed' ,top: "500px"}} onClick={() => {}}>MERGE LAYER</button>
          }
          <IconWidget handleBike={"Bike"} handleCar={"Car"} handleBus={"Bus"} handleHuman={"Pedestrian"} availData={["Bike", "Human", "Car", "Bus"]} heatmapOp= {false}/>
          <div  class={"HideSidebarHeatmap"} id={"HideSidebarHeatmapId"}>
            <HideSidebar text = {"Heatmap"} ></HideSidebar>
          </div>
          
          <div id={"SidebarGridHeatmap"} className={"MenuColor"}>
            
            <ContextLayers></ContextLayers>
          </div>
          <Legend></Legend>
          
        </>
      ) : MapValue === 1 ? (
        <>
          <IconWidget handleBike={"Bike"} handleCar={"Car"} handleBus={"Bus"} handleHuman={"Pedestrian"} availData={["Bike", "Human", "Car", "Bus"]} heatmapOp= {true}/>
          <SearchToGraphic/>
          <ZoomAndQuery />
          <HideSidebar text = {"Analitiką"} ></HideSidebar>
          <Sidebar data={layerData} token={token} />
        </>
      ): MapValue === 2 ?
      (
        <>
          {mapContext.state.multiSelect ? <IconWidgetMulti handleBike={"Car"} handleCar={"Car"} handleBus={null} handleHuman={null} availData={["Car", "Bike", "Human", "Bus"]}/> 
          :  <IconWidget handleBike={"Bike"} handleCar={"Car"} handleBus={"Bus"} handleHuman={"Pedestrian"} availData={["Bike", "Human", "Car", "Bus"]}/>}

          
          <ZoomAndQuery />
          <HideSidebar text= {"Palyginimą"}></HideSidebar>
          <CompareSidebar data={response} token={token} />
        </>
      )
      : MapValue === 3 ? 
      (
        <>
          <Grid/>
          <ZoomAndQuery customString={"Nėra parinktų gardelių"}/>
        </>
      )
      :
      <></>}
    </>
  );
}

export default Appv2;
