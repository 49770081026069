import * as featureService from "../Services/serviceLayers";
import services from "../Services/services";

function contextToLayer(data, transportation, time) {
  /*return;
  if (transportation === "Bike") {
    if (time === "Month") {
      return {
        featureLayer: featureService.strava_month_bikes,
        url: services.strava_bike_month_url,
      };
    }
    if (time === "Day") {
      return {
        featureLayer: featureService.strava_day_bikes,
        url: services.strava_bike_day_url,
      };
    }
    if (time === "Week") {
      return {
        featureLayer: featureService.strava_week_bikes,
        url: services.strava_bike_week_url,
      };
    }
    if (time === "Year") {
      return {
        featureLayer: featureService.strava_year_bikes,
        url: services.strava_bike_year_url,
      };
    }
  }
  if (transportation === "Pedestrian") {
    if (data === "ActiveVilnius") {
      if (time === "Month") {
        return {
          featureLayer: featureService.strava_month_pedestrians,
          url: services.strava_pedest_month_url,
        };
      }
      if (time === "Day") {
        return {
          featureLayer: featureService.strava_day_pedestrians,
          url: services.strava_pedest_day_url,
        };
      }
      if (time === "Week") {
        return {
          featureLayer: featureService.strava_week_pedestrians,
          url: services.strava_pedest_week_url,
        };
      }
      if (time === "Year") {
        return {
          featureLayer: featureService.strava_year_pedestrians,
          url: services.strava_pedest_year_url,
        };
      }
    }
    if (data === "Operator") {
      return {
        featureLayer: featureService.operator,
        url: services.operator,
      };
    } else {
      return {
        featureLayer: featureService.strava_month_pedestrians,
        url: services.strava_pedest_month_url,
      };
    }
  }
  if (transportation === "Car") {
    return {
      featureLayer: featureService.operator,
      url: services.operator,
      dataFeatureLayer: featureService.operatorData,
      dataurl: services.operatorData,
    };
  }*/
  return {};
}

function contextToLayer_02(data, transportation, time) {
  console.log("Started")
  console.log(data, transportation, time)
  let ret = { data: 0, transportation: 0, time: 0 };
  const urls = {
    strava_bike_day_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Strava_trips/FeatureServer/6", //strava dvir
    strava_bike_month_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Strava_trips/FeatureServer/7", //strava dviratininkai
    strava_bike_year_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Strava_trips/FeatureServer/9",
    strava_bike_week_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Strava_trips/FeatureServer/8",

    waze_jams: "https://services1.arcgis.com/usA3lHW20rGU6glp/arcgis/rest/services/waze_jams/FeatureServer/1", // waze duomenys

    strava_pedest_day_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Strava_trips/FeatureServer/1", //strava pestieji diena
    strava_pedest_month_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Strava_trips/FeatureServer/3", //strava pestieji menesis
    strava_pedest_year_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Strava_trips/FeatureServer/4",
    strava_pedest_week_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Strava_trips/FeatureServer/2",

    op_car_day_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/2",
    op_car_week_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/3",
    op_car_month_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/4",
    op_car_year_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/5",

    op_bike_day_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/5",
    op_bike_week_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/6",
    op_bike_month_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/7",
    op_bike_year_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/8",

    op_pedest_day_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/9",
    op_pedest_week_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/10",
    op_pedest_month_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/11",
    op_pedest_year_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/12",

    op_bus_day_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/13",
    op_bus_week_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/14",
    op_bus_month_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/15",
    op_bus_year_url: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/16",

    operator:"https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/0",
    operatorData:"https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/101",
    operatorAvailableDates: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/100",
    operatorBikeData: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/102",
    operatorPedestrianData: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/103",
    operatorBusData: "https://opencity.vplanas.lt/map/rest/services/P_Judumas/Judumas_OP_data_update/FeatureServer/104",

    // Automobilio operatoriu duomenys
  };

  const urlArr = [
    [
      [urls.strava_bike_day_url, urls.strava_bike_week_url, urls.strava_bike_month_url, urls.strava_bike_year_url],
      [urls.strava_pedest_day_url, urls.strava_pedest_week_url, urls.strava_pedest_month_url, urls.strava_pedest_year_url],
      [],
    ],
    [
        [urls.op_bike_day_url, urls.op_bike_week_url, urls.op_bike_month_url, urls.op_bike_year_url], 
        [urls.op_pedest_day_url, urls.op_pedest_week_url, urls.op_pedest_month_url, urls.op_pedest_year_url], 
        [urls.op_car_day_url, urls.op_car_week_url, urls.op_car_month_url, urls.op_car_year_url],
        [urls.op_bus_day_url, urls.op_bus_week_url, urls.op_car_month_url, urls.op_pedest_year_url]
    ],
    [
        [urls.operator, urls.operatorPedestrianData, urls.operator],
        [urls.operator, urls.operatorBikeData, urls.operator],
        [urls.operator, urls.operatorData, urls.operator],
        [urls.operator, urls.operatorBusData, urls.operator],
    ],
  ];
  console.log(featureService.heatmapArr)
  return {
    featureLayer: featureService.heatmapArr[data][transportation][time],
    url: urlArr[data][transportation][time],
  };
  return {};
}

export default { contextToLayer, contextToLayer_02 };
